import validator from 'validator'
import httpService from '@services/httpService'
import safeJSONparse from '@services/safeJSONparse'

export const fields = [
  'name', 'domain'
]

export const getTenantHeaders = () =>
  fields.map(field => ({
    title: I18n.t(`activerecord.attributes.v4/tenant.${field}`),
    field
  }))

export const validate = (values, fields = []) => {
  const requiredFields = _.isEmpty(fields) ? ['name', 'domain', 'mail_from'] : fields
  const errors = {}
  const regexDomain = /^((https?:\/\/[a-z0-9]*([a-z0-9]*[-]*[a-z0-9]+)+)([a-z0-9]+\.([a-z0-9]+)([a-z0-9]*[-]*)|([-]*[a-z0-9]*))*[a-z0-9](\:{1}([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$)?)/

  requiredFields.forEach(field => {
    if (field === 'mail_from' && values.use_default_configuration) return

    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  if (values.domain) {
    const results = regexDomain.exec(values.domain) || []
    if (_.isEmpty(results)) {
      errors.domain = I18n.t('validations.invalid_url')
    } else {
      if (results[0].length !== values.domain.length) {
        errors.domain = I18n.t('validations.invalid_url')
      }
    }
  }

  if (_.isEmpty(fields) && !values.use_default_configuration) {
    errors.smtp_settings = validateSmtpSettings(values.smtp_settings)
  }

  return errors
}


const validateSmtpSettings = (values = {}) => {
  const domainFields = ['domain', 'address',]
  const requiredFields = ['port', 'domain', 'address', 'password', 'user_name']
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  domainFields.forEach(field => {
    if (values[field]) {
      if (!validator.isURL(values[field])) errors[field] = I18n.t('validations.invalid_url')
    }
  })

  return errors
}

export const handleTenantSubmit = async ({ data, url, method, successUrl }) => {
  const formFields = [
    'name', 'domain', 'index_url', 'logo', 'theme', 'small_logo', 'allow_sign_up', 'login_screen_logo',
    'login_screen_background', 'product_ids', 'smtp_settings', 'use_default_configuration', 'mail_from',
    'show_support', 'show_store', 'trial_enabled', 'days_available', 'show_app',
     'enabled_field_notebook', 'expiration_months', 'template_code'
  ]

  if (data.smtp_settings) {
    data.smtp_settings = JSON.stringify(data.smtp_settings)
  }

  const formData = new FormData()
  const sendData = serialize(data)

  formFields.forEach(field => {
    if (field in data) {
      if ((field === 'mail_from' || field === 'smtp_settings') && data.use_default_configuration) return

      formData.append(`tenant[${field}]`, sendData[field])
    }
  })

  await httpService[method](url, formData)
  Turbolinks.visit(successUrl)
}

export const deserialize = (tenant) => ({
  ...tenant,
  theme: safeJSONparse(tenant.theme),
  smtp_settings: safeJSONparse(tenant.smtp_settings)
})

export const serialize = (tenant) => ({
  ...tenant,
  theme: JSON.stringify(tenant.theme)
})
