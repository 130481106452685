import React from 'react'

import Paper from '@material-ui/core/Paper'
import { Typography, IconButton, Dialog, Grid } from '@material-ui/core'
import PreviewIcon from '@material-ui/icons/ImageSearch'
import withStyles from '@material-ui/core/styles/withStyles'
import SettingsIcon from '@material-ui/icons/Settings'
import EmailIcon from '@material-ui/icons/Email'
import StylesIcon from '@material-ui/icons/ColorLens'
import LoginScreenIcon from '@material-ui/icons/WebAsset'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import Steppers from './Steppers'
import ColorsForm from './ColorsForm/Index'
import LoginScreenForm from './LoginScreenForm/Index'
import EmailConfigurationForm from './EmailConfigurationForm'
import TenantPreview from './TenantPreview'


import { Field } from 'react-final-form'

import { validate } from '@models/admin/tenant'
import ControlledFormField from '@ui/Fields/ControlledFormField'
import Dropzone from '@ui/Fields/Dropzone'
import MultipleSelect from '@ui/Fields/MultipleSelect'
import TextField from '@ui/Fields/TextField'

import { mountNewTheme } from '@theme/mountTheme'
import styles from '@theme/styles/forms'

import { blockCharacters } from '@models/account/payment_profiles'

const Form = ({
  classes,
  handleSubmit,
  title,
  values,
  products,
  isAdmin,
  o
}) => {
  const [state, setState] = React.useState({
    login_screen_logo: values.login_screen_logo,
    login_screen_background: values.login_screen_background,
    theme: mountNewTheme(values.theme),
    allow_sign_up: values.allow_sign_up,
    show_support: values.show_support,
    show_store: values.show_store,
    show_app: values.show_app,
    trial_enabled: values.trial_enabled,
    enabled_field_notebook: values.enabled_field_notebook
  })

  const [previewOpen, togglePreview] = React.useState(false)

  const handleChange = (name, value) => {
    setState({
      ...state,
      [name]: value
    })

    values[name] = value
  }

  return <form
    className={classes.form}
    data-testid='tenants-form'
  >
    <Paper className={classes.form__wrapper} elevation={0}>
      <header className={classes.form__header}>
        <Typography component='h2' variant='h5'>
          {title}
        </Typography>

        <IconButton
          title={I18n.t('v4/tenant.preview.view_preview')}
          onClick={() => togglePreview(!previewOpen)}
        >
          <PreviewIcon />
        </IconButton>
      </header>
      <main className={classes.form__fields}>
        <Steppers
          formClasses={classes}
          canAdvance={
            _.isEmpty(validate(
              values,
              ['name', 'logo', 'domain', 'small_logo'].filter(field =>
                !values[`${field}_url`]
              )
            ))
          }
          handleSubmit={handleSubmit}
          cancelLink={`/v4/o/${o}/admin/tenants`}
          steps={{
            1: {
              icon: <SettingsIcon />,
              label: I18n.t('v4/tenant.steps.configuration')
            },
            2: {
              icon: <StylesIcon />,
              label: I18n.t('v4/tenant.steps.styles')
            },
            3: {
              icon: <LoginScreenIcon />,
              label: I18n.t('v4/tenant.steps.login_screen')
            },
            4: {
              icon: <EmailIcon />,
              label: I18n.t('v4/tenant.steps.email_configuration')
            }
          }}
        >
          <div>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/tenant.name')}
                name='name'
                required
              />
            </div>

            <div className={classes.form__field__wrapper} data-testid="logo">
              <Dropzone
                label={`${I18n.t('activerecord.attributes.v4/tenant.logo')} *`}
                previewImage={values.logo_url}
                currentFileName={values.logo ? values.logo.name : ''}
                setFile={file => values['logo'] = file}
                className={classes.form__fieldWithError}
              />
            </div>

            <div className={classes.form__field__wrapper} data-testid="small_logo">
              <Dropzone
                label={`${I18n.t('activerecord.attributes.v4/tenant.small_logo')} *`}
                previewImage={values.small_logo_url}
                currentFileName={values.small_logo ? values.small_logo.name : ''}
                setFile={file => values['small_logo'] = file}
                className={classes.form__fieldWithError}
              />
            </div>

            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/tenant.domain')}
                name='domain'
                disabled={!isAdmin}
                required
              />
            </div>

            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/tenant.index_url')}
                name='index_url'
                disabled={!isAdmin}
                required
              />
            </div>
            <div className={classes.form__field__wrapper}>
              <Field
                format={value => Array.isArray(value) ? value : []}
                component={MultipleSelect}
                className={classes.form__fieldWithError}
                data={products}
                fullWidth
                margin='normal'
                fields={{ id: 'id', name: 'label' }}
                label={I18n.t('activerecord.models.v4/product.other')}
                name='product_ids'
                disabled={!isAdmin}
              />
            </div>
            <div className={classes.form__checkbox_list}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.allow_sign_up}
                    onChange={() => handleChange('allow_sign_up', !state.allow_sign_up)}
                    color='primary'
                  />
                }
                label={I18n.t('activerecord.attributes.v4/users.allow_sign_up')}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.show_support}
                    onChange={() => handleChange('show_support', !state.show_support)}
                    color='primary'
                  />
                }
                label={I18n.t('activerecord.attributes.v4/users.show_support')}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.show_store}
                    onChange={() => handleChange('show_store', !state.show_store)}
                    color='primary'
                  />
                }
                label={I18n.t('activerecord.attributes.v4/users.show_store')}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.show_app}
                    onChange={() => handleChange('show_app', !state.show_app)}
                    color='primary'
                  />
                }
                label={I18n.t('activerecord.attributes.v4/users.show_app')}
              />

              <Grid container>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.trial_enabled}
                      onChange={() => handleChange('trial_enabled', !state.trial_enabled)}
                      color='primary'
                    />
                  }
                  label={I18n.t('activerecord.attributes.v4/users.enable_trial')}
                />

                {
                  state.trial_enabled && <Grid item xs={3}>
                    <div className={classes.form__field__wrapper}>
                      <ControlledFormField
                        component={TextField}
                        className={classes.form__fieldWithError}
                        margin='normal'
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        label={I18n.t('activerecord.attributes.v4/users.number_of_days')}
                        name='days_available'
                        type='number'
                        required
                        InputProps={{
                          inputProps: {
                            className: classes.form__input_number_no_arrows
                          }
                        }}
                        onChange={() => blockCharacters('days_available')}
                      />
                    </div>
                  </Grid>
                }
              </Grid>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.enabled_field_notebook}
                    onChange={() => handleChange('enabled_field_notebook', !state.enabled_field_notebook)}
                    color='primary'
                  />
                }
                label={I18n.t('activerecord.attributes.v4/tenant.enabled_field_notebook')}
              />

              {
                state.enabled_field_notebook &&  <Grid container spacing={24}>
                  <Grid item xs={6}>
                    <div className={classes.form__field__wrapper}>
                      <Field
                        component={TextField}
                        className={classes.form__fieldWithError}
                        margin='normal'
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        label={I18n.t('activerecord.attributes.v4/tenant.template_code')}
                        name='template_code'
                        disabled={!isAdmin}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className={classes.form__field__wrapper}>
                      <Field
                        component={TextField}
                        className={classes.form__fieldWithError}
                        margin='normal'
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        label={I18n.t('activerecord.attributes.v4/tenant.expiration_months')}
                        name='expiration_months'
                        disabled={!isAdmin}
                      />
                    </div>
                  </Grid>
                </Grid>
              }
            </div>
          </div>

          <ColorsForm
            setThemeValues={(theme) => handleChange('theme', theme)}
            theme={state.theme}
          />

          <LoginScreenForm
            handleChange={handleChange}
            backgroundPreview={values.login_screen_background_url}
            logoPreview={values.login_screen_logo_url}
            login_screen_logo={state.login_screen_logo}
            login_screen_background={state.login_screen_background}
            classes={classes}
          />

          <EmailConfigurationForm
            classes={classes}
            use_default_configuration={values.use_default_configuration}
          />
        </Steppers>

        <Dialog
          fullScreen
          onClose={() => togglePreview(!previewOpen)}
          open={previewOpen}
          PaperProps={{
            style: {
              backgroundColor: state.theme.palette.background.default
            }
          }}
        >
          <TenantPreview
            handleClose={() => togglePreview(!previewOpen)}
            logo_url={
              values.logo_url
                ? values.logo_url
                : values.logo
                  ? URL.createObjectURL(values.logo)
                  : values.logo
            }
            small_logo_url={
              values.small_logo_url
                ? values.small_logo_url
                : values.small_logo
                  ? URL.createObjectURL(values.small_logo)
                  : values.small_logo
            }
            theme={state.theme}
          />
        </Dialog>
      </main>
    </Paper>
  </form>
}

export default withStyles(styles)(Form)
